import { merge } from 'lodash'
import {
	groupEndpointMapping as baseGroupEndpointMapping,
	EndpointMapping,
	EndpointMappings,
	endpointMappings,
	entityUserRoleEndpointMappings
} from 'studiokit-scaffolding-js'

const groupEndpointMapping: EndpointMapping = {
	...baseGroupEndpointMapping,
	userTimerMultipliers: {
		_config: {
			isCollection: true
		}
	}
}

const homeEndpointMapping: EndpointMapping = {
	_config: {
		isCollection: true,
		fetch: {
			path: '/api/groups/home'
		}
	}
}

const problemInstanceEndpointMapping: EndpointMapping = {
	statements: {
		_config: {
			isCollection: true
		},
		solutionInstances: {
			_config: {
				isCollection: true
			},
			attempts: {
				_config: {
					isCollection: true
				}
			}
		}
	}
}

const assessmentEndpointMapping: EndpointMapping = {
	_config: {
		isCollection: true,
		fetch: {
			path: '/api/assessments'
		}
	},
	preview: {
		_config: {
			isCollection: true
		},
		...problemInstanceEndpointMapping
	},
	...entityUserRoleEndpointMappings('assessmentUserRoles')
}

const problemEndpointMapping: EndpointMapping = {
	_config: {
		isCollection: true,
		fetch: {
			path: '/api/problems'
		}
	},
	preview: {
		...problemInstanceEndpointMapping
	},
	errorDetails: {
		_config: {
			isCollection: true
		}
	},
	problemSharedLibraryRoles: {
		_config: {
			isCollection: true,
			fetch: {
				path: '/api/problemSharedLibraryRoles'
			}
		}
	},
	references: {},
	...entityUserRoleEndpointMappings('problemUserRoles')
}

const sharedLibraryEndpointMapping: EndpointMapping = {
	_config: {
		isCollection: true,
		fetch: {
			path: '/api/sharedLibraries'
		}
	},
	problemSharedLibraryRoles: {
		_config: {
			isCollection: true,
			fetch: {
				path: '/api/problemSharedLibraryRoles'
			}
		}
	},
	// use separate model for transient PUTs
	modifyProblemSharedLibraryRoles: {
		_config: {
			fetch: {
				path: '/api/modifyProblemSharedLibraryRoles',
				method: 'PUT'
			}
		}
	},
	...entityUserRoleEndpointMappings('sharedLibraryUserRoles')
}

const groupAssessmentEndpointMapping: EndpointMapping = {
	_config: {
		isCollection: true,
		fetch: {
			path: '/api/groupAssessments'
		}
	},
	assessments: {
		_config: {
			isCollection: true,
			fetch: {
				path: '/api/assessments'
			}
		}
	},
	problemInstances: {
		_config: {
			isCollection: true
		},
		...problemInstanceEndpointMapping
	},
	results: {
		_config: {
			isCollection: true
		}
	},
	learnerStatuses: {
		_config: {
			isCollection: true
		}
	},
	manualScoring: {
		_config: {
			isCollection: true
		},
		responses: {
			_config: {
				isCollection: true
			}
		},
		attempts: {
			_config: {
				isCollection: true
			}
		}
	},
	scores: {},
	pushGrades: {
		_config: {
			fetch: {
				method: 'POST'
			}
		}
	},
	resultsCsv: {
		_config: {
			fetch: {
				path: '/api/groupAssessments/{:groupAssessmentId}/results',
				headers: {
					Accept: 'text/csv'
				}
			}
		}
	},
	scoresCsv: {
		_config: {
			fetch: {
				path: '/api/groupAssessments/{:groupAssessmentId}/scores',
				headers: {
					Accept: 'text/csv'
				}
			}
		}
	}
}

const appEndpointMappings: EndpointMappings = merge({}, endpointMappings, {
	groups: {
		...groupEndpointMapping
	},
	home: {
		...homeEndpointMapping
	},
	assessments: {
		...assessmentEndpointMapping
	},
	problems: {
		...problemEndpointMapping
	},
	sharedLibraries: {
		...sharedLibraryEndpointMapping
	},
	groupAssessments: {
		...groupAssessmentEndpointMapping
	},
	// this endpoint is not nested on each collection item in `groupAssessments`, so it is defined separately
	groupAssessmentsPurgeCache: {
		_config: {
			fetch: {
				path: '/api/groupAssessments/purgeCache',
				method: 'DELETE'
			}
		}
	},
	search: {
		groups: {
			...groupEndpointMapping
		},
		assessments: {
			...assessmentEndpointMapping
		},
		problems: {
			...problemEndpointMapping
		},
		sharedLibraries: {
			...sharedLibraryEndpointMapping
		}
	}
})

export default appEndpointMappings

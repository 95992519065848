/* eslint-disable import/first,react-refresh/only-export-components */
/* eslint-disable import/order */

// polyfills
import 'core-js'
import 'element-scroll-polyfill'

import {
	createGenerateClassName,
	createMuiTheme,
	jssPreset,
	MuiThemeProvider,
	StylesProvider
} from '@material-ui/core/styles'
import { ConnectedRouter } from 'connected-react-router'
import { Decimal } from 'decimal.js'
import { create } from 'jss'
import ReactDOM from 'react-dom'
import { Quill } from 'react-quill'
import { Provider } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { PersistGate } from 'redux-persist/integration/react'
import { dispatchModelFetchRequest } from 'studiokit-scaffolding-js'
import AccessibleAppComponent from 'studiokit-scaffolding-js/lib/components/HOC/AccessibleAppComponent'
import AsyncComponent from 'studiokit-scaffolding-js/lib/components/HOC/AsyncComponent'
import { FormulaBlot } from './components/Shared/QuillEdit/Blots/FormulaBlot'
import { VariableBlot } from './components/Shared/QuillEdit/Blots/VariableBlot'
import { registerQuill } from 'studiokit-scaffolding-js/lib/components/Quill'
import { ReduxState } from './types/ReduxState'
import * as serviceWorker from './utils/serviceWorker'

//#region CSS

import 'bootstrap/dist/css/bootstrap.min.css'
import 'mathlive/dist/mathlive.core.css'
import 'mathlive/dist/mathlive.css'
import 'react-quill/dist/quill.snow.css'
import 'studiokit-scaffolding-js/lib/components/Quill/TableModule/index.css'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import 'react-table/react-table.css'
import 'studiokit-scaffolding-js/lib/css/variables.css'
import './css/variables.css'
import 'studiokit-scaffolding-js/lib/css/index.css'
import './css/index.css'

// `react-scripts@2.x` wants to minify bootstrap and tachyon css together
// causing tachyons to load _before_ index.css, which breaks hierarchy
// using `AsyncComponent` and this dummy component forces them to be split
const Tachyons = AsyncComponent(() => import('./components/Tachyons'), false)

//#endregion CSS

//#region globals

// @ts-ignore: vite does not provide node global value like create-react-app did.
window.global ||= window

//#endregion globals

//#region decimal.js

Decimal.set({ precision: 100 })

//#endregion decimal.js

//#region Quill

Quill.register(VariableBlot)
Quill.register(FormulaBlot, true)

registerQuill()

//#endregion Quill

//#region JSS + material-ui

const generateClassName = createGenerateClassName()
const insertionPoint = document.getElementById('jss-insertion-point')
const jss = create({
	...jssPreset(),
	// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
	insertionPoint: insertionPoint || '#jss-insertion-point'
})

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#4450be'
		},
		error: {
			main: '#C94349'
		},
		success: {
			main: '#7bcf64'
		}
	},
	typography: {
		fontFamily: [
			'Open Sans',
			'"Helvetica Neue Light"',
			'"Helvetica Neue"',
			'Helvetica',
			'Arial',
			'sans-serif'
		].join(',')
	}
})

//#endregion JSS + material-ui

//#region App

// (imported after other css)
import App from './components/App'

// Note: override TOwnProps generic, since all `AppProps` are provided by the internal `connect()`
const Application = AccessibleAppComponent<RouteComponentProps>(App)

//#endregion App

//#region Scaffolding

import { AnyAction, Reducer } from 'redux'
import { setOnPostLoginSaga, setOtherReducers, startup, updatePersistBlacklist } from 'studiokit-scaffolding-js'
import configuration from './configuration'
import endpointMappings from './endpointMappings'
import keyboardsReducer, { KeyboardsState } from './redux/reducers/keyboardsReducer'

setOtherReducers({
	keyboards: keyboardsReducer as Reducer<KeyboardsState, AnyAction>
})

updatePersistBlacklist(['keyboards'])

/* eslint-disable-next-line require-yield */
function* postLoginSaga() {
	dispatchModelFetchRequest({ modelName: 'assessments' })
	dispatchModelFetchRequest({ modelName: 'problems' })
	dispatchModelFetchRequest({ modelName: 'sharedLibraries' })
	return true
}

setOnPostLoginSaga(postLoginSaga)

const containerElementId = 'root'
const { history, store, persistor } = startup<ReduxState>(configuration, endpointMappings)

//#endregion Scaffolding Startup

ReactDOM.render(
	<Provider store={store} key="provider">
		<PersistGate loading={null} persistor={persistor}>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<MuiThemeProvider theme={theme}>
					<ConnectedRouter history={history}>
						<>
							<Tachyons />
							<Application />
						</>
					</ConnectedRouter>
				</MuiThemeProvider>
			</StylesProvider>
		</PersistGate>
	</Provider>,
	document.getElementById(containerElementId)
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()

import { TIER } from 'studiokit-scaffolding-js/lib/constants/tier'
import { AppConfiguration } from 'studiokit-scaffolding-js/lib/types/AppConfiguration'
import packageInfo from '../package.json'

const windowEnv: ImportMetaEnv = (window as any)._env_ || {}
const processEnv = import.meta.env

/**
 * Global Configuration values for the app
 *
 * Combines values from the following sources:
 * * Defaults values in this file
 *   * Values that will not change based on tier
 * * Environment Variables prefixed with `VITE_`
 *   * Useful for local development using `.env` files and `env-cmd`
 * * `public/env.js`
 *   * A static file that adds overrides to `windowEnv` and can be modified after build at runtime
 */
const config: AppConfiguration = {
	NODE_ENV: processEnv.PROD ? 'production' : 'development',

	// App Version + Name
	APP_NAME: 'Variate',
	VERSION: packageInfo.version,
	VERSION_NAME: '"Purple Pedal Boat"',

	// Tier and Base URL
	TIER: (windowEnv.VITE_TIER as TIER) || (processEnv.VITE_TIER as TIER) || TIER.LOCAL,
	API_BASE_URL: windowEnv.VITE_API_URL || processEnv.VITE_API_URL || 'http://vm',
	ROOT_DOMAIN: 'variate.org',
	LOCALHOST_SHARD_KEY: windowEnv.VITE_LOCALHOST_SHARD_KEY || processEnv.VITE_LOCALHOST_SHARD_KEY || undefined,

	// Custom Storage URL for local development against vm
	STORAGE_URL: windowEnv.VITE_STORAGE_URL || processEnv.VITE_STORAGE_URL || undefined,

	// Caliper
	CALIPER_EXTENSIONS_NAMESPACE: 'org.variate',
	CALIPER_SENSOR_ID: 'https://variate.org/sensor',
	CALIPER_SENSOR_APP_ID: 'https://variate.org',
	CALIPER_SENSOR_APP_NAME: 'Variate',
	CALIPER_EVENT_STORE_OPTIONS: {
		protocol: 'https:',
		port: '443"',
		path: '/collector',
		method: 'POST'
	},

	// Google Analytics
	GOOGLE_ANALYTICS_TRACKING_ID:
		windowEnv.VITE_GOOGLE_ANALYTICS_TRACKING_ID || processEnv.VITE_GOOGLE_ANALYTICS_TRACKING_ID || undefined,

	// Sentry
	SENTRY_DSN: windowEnv.VITE_SENTRY_DSN || processEnv.VITE_SENTRY_DSN || undefined,
	SENTRY_TRACES_SAMPLE_RATE:
		windowEnv.VITE_SENTRY_TRACES_SAMPLE_RATE || processEnv.VITE_SENTRY_TRACES_SAMPLE_RATE
			? Number(windowEnv.VITE_SENTRY_TRACES_SAMPLE_RATE || processEnv.VITE_SENTRY_TRACES_SAMPLE_RATE)
			: undefined,
	SENTRY_REPLAYS_SESSION_SAMPLE_RATE:
		windowEnv.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || processEnv.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
			? Number(
					windowEnv.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ||
						processEnv.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
			  )
			: undefined,
	SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE:
		windowEnv.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || processEnv.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
			? Number(
					windowEnv.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ||
						processEnv.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE
			  )
			: undefined,

	// Application Insights
	APP_INSIGHTS_KEY:
		windowEnv.VITE_APPINSIGHTS_INSTRUMENTATIONKEY || processEnv.VITE_APPINSIGHTS_INSTRUMENTATIONKEY || undefined,

	// OAuth
	CLIENT_ID: windowEnv.VITE_CLIENT_ID || processEnv.VITE_CLIENT_ID || 'web',
	CLIENT_SECRET: windowEnv.VITE_CLIENT_SECRET || processEnv.VITE_CLIENT_SECRET || '****',

	// Downtime
	IS_DOWNTIME: Boolean(windowEnv.VITE_IS_DOWNTIME) || Boolean(processEnv.VITE_IS_DOWNTIME) || false,

	// LMS
	LMS_NAME: 'Brightspace',

	// Gradable (Ex: Assessment or Assignment) UI Language
	GRADABLE_DESCRIPTOR: 'assessment'
}

export default config

import { Dictionary } from 'lodash'
import MathLive from 'mathlive'
import ParchmentType from 'parchment'
import { Blot } from 'parchment/dist/src/blot/abstract/blot'
import { Quill } from 'react-quill'
import { getFirstParentElementWithClassName } from 'studiokit-scaffolding-js/lib/utils/dom'
import {
	latexENotationRegEx,
	latexFractionRegEx,
	latexNumberRegEx,
	latexNumberWithSeparatorsRegEx,
	latexSciNotationRegEx
} from '../../../../constants/variable'
import { appendCopyToClipboardDOMNode } from '../../../../utils/copyToClipboard'
import { getMathlistFontScaleFactor } from '../../../../utils/mathlive'

const Parchment = Quill.import('parchment') as typeof ParchmentType
const Embed = Quill.import('blots/embed') as typeof ParchmentType.Embed

type FormulaBlotOnClick = (latex: string, blot: Blot) => void
type FormulaBlotOnCreate = (formula: string, mathlist: any) => void

let clickHandlersEnabled = true
const onClickHandlers: Dictionary<FormulaBlotOnClick | undefined> = {}
const onCreateHandlers: Dictionary<FormulaBlotOnCreate | undefined> = {}

export function setFormulaBlotClickHandlersEnabled(value: boolean) {
	clickHandlersEnabled = value
}

export function setOnFormulaBlotClick(guid: string, value: FormulaBlotOnClick | undefined) {
	if (value) {
		onClickHandlers[guid] = value
	} else {
		delete onClickHandlers[guid]
	}
}

export function setOnFormulaBlotCreate(guid: string, value: FormulaBlotOnCreate | undefined) {
	if (value) {
		onCreateHandlers[guid] = value
	} else {
		delete onCreateHandlers[guid]
	}
}

export class FormulaBlot extends Embed {
	static blotName = 'formula'
	static tagName = 'SPAN'
	static className = 'ql-formula'

	static create(value: any) {
		const node = super.create(value) as HTMLSpanElement
		if (typeof value === 'string') {
			node.setAttribute('data-value', value)

			// disable the math font and the clipboard button
			// if the formula value matches a variable number format
			// see also `utils/quill/populateVariableValues`
			let disableMathFontAndClipboard = false
			if (latexNumberRegEx.exec(value)) {
				disableMathFontAndClipboard = true
				latexNumberRegEx.lastIndex = 0
			} else if (latexNumberWithSeparatorsRegEx.exec(value)) {
				disableMathFontAndClipboard = true
				latexNumberWithSeparatorsRegEx.lastIndex = 0
			} else if (latexENotationRegEx.exec(value)) {
				disableMathFontAndClipboard = true
				latexENotationRegEx.lastIndex = 0
			} else if (latexSciNotationRegEx.exec(value)) {
				disableMathFontAndClipboard = true
				latexSciNotationRegEx.lastIndex = 0
			} else if (latexFractionRegEx.exec(value)) {
				disableMathFontAndClipboard = true
				latexFractionRegEx.lastIndex = 0
			}

			// Render MathLive inline
			const child = document.createElement('script')
			child.textContent = value
			child.setAttribute('data-value', value)
			child.setAttribute('type', 'math/tex')
			node.append(child)
			// disable math font and clipboard, if required
			if (disableMathFontAndClipboard) {
				node.classList.add('plain-text')
			}
			MathLive.renderMathInElement(node, {
				renderAccessibleContent: 'speakable-text',
				onCreateMathlist: (mathlist: any) => {
					// wrap mathlist (array of MathAtoms) in a "root" atom
					// allows use of mathlive's custom `forEach` method
					const rootAtom = MathLive.MathAtom.makeRoot('math', mathlist)

					// trigger callbacks
					// since the node is not attached to the DOM yet
					// we don't know which handler to call
					// so call them all and let each one decide if this
					// create is applicable
					for (const key in onCreateHandlers) {
						const onCreate = onCreateHandlers[key]
						if (onCreate) {
							onCreate(value, rootAtom)
						}
					}

					// set dynamic font size, if still using math font
					if (!disableMathFontAndClipboard) {
						const fontSizeScaleFactor = getMathlistFontScaleFactor(rootAtom)
						node.classList.add(`math-scale-${fontSizeScaleFactor}`)
					}
				}
			})

			const clickOverlay = document.createElement('span')
			clickOverlay.className = 'ql-formula-click-overlay'
			clickOverlay.addEventListener('click', (ev: Event) => {
				const blot = Parchment.find(node)
				const quillEdit = getFirstParentElementWithClassName(node, 'QuillEdit')
				if (!quillEdit) {
					return
				}
				const onClick = onClickHandlers[quillEdit.id]
				if (onClick && clickHandlersEnabled && blot) {
					onClick(value, blot)
				}
			})
			node.append(clickOverlay)

			// Add LaTeX to DOM for copy-paste
			const latexChild = document.createElement('span')
			latexChild.textContent = value
			latexChild.setAttribute('class', 'visually-hidden')
			latexChild.setAttribute('aria-hidden', 'true')
			node.append(latexChild)

			// Add button to DOM for copy to clipboard
			appendCopyToClipboardDOMNode(node, value)
		}
		return node
	}

	static value(node: HTMLSpanElement) {
		return node.getAttribute('data-value')
	}
}
